
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./banners/banner1.scss";
@import "./banners/banner2.scss";
@import "./banners/banner3.scss";
@import "./banners/banner4.scss";
@import "./mainLayout/footer.scss";
@import "./mainLayout/header.scss";
@import "./mainLayout/main.scss";
@import "./banners/banner1sectors.scss";

@import "./health/banner1-health.scss";
@import "./health/banner2-health.scss";
@import "./health/banner3-health.scss";
@import "./health/banner4-health.scss";
@import "./health/layout/footer-health.scss";
@import "./health/layout/header-health.scss";
@import "./health/layout/main-health.scss";

@import "./components/health/general-button.scss";

body,
html {
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
  background: $white;
  overflow-x: hidden;
  overscroll-behavior: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}