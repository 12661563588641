
.banner-2-health-container {
    overflow-x: hidden;
    position: relative;
    overflow-y: hidden;
    display: flex;
    width: 100vw;
    height: 85vh;
    flex-direction: row;
    margin: 0px 120px 0px 120px;
    @include media("<=tablet") {
        margin: 0px 20px 0px 20px;
        padding-top: 20px;
    }
    .banner-2-health-container-left {
        width: 50%;
        @include media("<=tablet") {
            display: none;
        }
        .banner-2-health-img {
            border-radius: 4px;
        }
       
    }
    .banner-2-container-right {
        width: 50%;
        margin-right: 120px;
        @include media("<=tablet") {
           width:100%;
           margin-right: 20px;
        }
        .banner-2-health-title {
            height: 10%;
            font-size: 42px;
            color: #1A1F66;
            font-weight: 700;
            @include media("<=desktop") {
                height: 20%;
            }
            
        }
        .banner-2-content-container {
            height: 90%;
            line-height: 36px;
            @include media("<=desktop") {
                height: 80%;
            }
        }
    }
}