.footer-health-container {
    width: 100%;
    position: relative;
    height: 320px;
    background: white;
    overflow-y: hidden;
    @include media("<=tablet") {
        height: 180px;
    }
    .footer-svg-background {
        position: absolute;
        z-index: 1;
        bottom: 0;
        margin-bottom:-40px ;
        left: 0;
        @include media("<=tablet") {
            display: none;
        }
        
    }
    .footer-health-overlay-container {
        position: absolute;
        display: flex;
        flex-direction: row;
        bottom: 0;
        left: 0;
        z-index: 4;
        height: 60px;
        
        width: 100%;
        font-size: 40px;
        background-color:  #1A1F66;
        @include media("<=desktop") {
            height: 60px;
           
        }
        @include media("<=tablet") {
            height: 120px;
           
        }
        .footer-health-container-left {
            position: relative;
            height: 100%;
            width: 50%;
            background-color:  #1A1F66;
            .footer-health-logo-container {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                padding-left: 120px;
                
                @include media("<=tablet") {
                    padding-left: 20px;
                }
                .footer-health-logo {
                    height: 46px;
                    @include media("<=desktop") {
                        height: 36px;
                        padding-bottom: 40px;
                    }
                    @include media("<=tablet") {
                        height: 24px;
                        
                    }
                }
            }
        }
        .footer-health-container-right {
            position: relative;
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color:  #1A1F66;
            .footer-contact-anchors-list {
                position: relative;
                margin: 0px;
                list-style: none;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                @include media("<=tablet") {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-top: 60px;
                    margin-left: 0px;
                    padding-left: 0px;
                }
                .footer-health-contact-anchor-separator {
                    position: relative;
                    height: 100%;
                    color: white;
                    text-decoration: none;
                    font-size: 28px;
                    outline: none;
                    transition: .4s color ease-in-out;
                    @include media("<=desktop"){
                        font-size: 20px;
                    }
                    @include media("<=tablet"){
                        display: none;
                    }
                }
                .footer-health-contact-anchor {
                    position: relative;
                    height: 100%;
                    color: white;
                text-decoration: none;
                font-size: 28px;
                outline: none;
                transition: .4s color ease-in-out;
                &:after {
                    content: "";
                    position: relative;
                    width: 0;
                    height: 2px;
                    display: block;
                    margin-top: 5px;
                    right: 0;
                    background-color: #009344;
                    transition: 0.4s ease;
                }
                &:hover {
                    cursor: pointer;
                    color:  #009344;
                    &:after {
                        position: relative;
                        width: 100%;
                        left: 0;
                        background-color: #009344;
                    }
                }
                @include media("<=desktop"){
                    font-size: 20px;
                }
                @include media("<=tablet"){
                    font-size: 12px;
                    margin-left: 0px;
                    padding-left: 0px;
                    height: auto;
                }
                }   
            }
        }
    }
}
