.footer-container {
    background-color: #1A1F66;;
    z-index: 4;
    min-height: 200px;
    outline: none;
    position: relative;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    width: 100vw;
    grid-template-columns: repeat(2, 1fr);
    @include media("<=tablet"){
        padding-top: 40px;
        width: 100vw;
       
        display: flex;
        flex-direction: column;
    }
    .container-right {
        display: flex;
       
        flex-direction: row;
        justify-content: center;
        align-items: center;
       
        .footer-anchor {
            color:  white;
            text-decoration: none;
            padding:10px;
            outline: none;
            transition: .4s color ease-in-out;
        
        .footer-logo {
            margin-left: -60px;
            object-fit: cover;
            height:60px;
            border-radius: 10px;
            transition: all 1s ease-in-out;
            &:hover {
                transform: scale(1.1)
            }
            @include media("<=tablet") {
                height:30px;
                
            }
        }
            // &:after {
            //     content: "";
            //     position: relative;
            //     width: 0;
            //     height: 2px;
            //     display: block;
            //     margin-top: 5px;
            //     right: 0;
            //     background: $primary;
            //     transition: 0.4s ease;
            // }
            // &:hover {
            //     color: $primary;
            //     &:after {
            //         position: relative;
            //         width: 100%;
            //         left: 0;
            //         background: $primary;
            //     }
            // }
        }
        
    }

    .container-left {
       
        grid-column: 2 / 3;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @include media("<=phone") {
            margin-right: 8px;
        
        }

        .container-left-anchors {
            display: flex;
            flex-direction: column;
            @include media("<=tablet"){
                font-size: 14px;
            }
            .footer-anchor {
                padding: 10px;
                color: white;
                text-decoration: none;
                font-size: 18px;
                outline: none;
                transition: .4s color ease-in-out;
                &:after {
                    content: "";
                    position: relative;
                    width: 0;
                    height: 2px;
                    display: block;
                    margin-top: 5px;
                    right: 0;
                    background-color: #009344;
                    transition: 0.4s ease;
                }
                &:hover {
                    color:  #009344;
                    &:after {
                        position: relative;
                        width: 100%;
                        left: 0;
                        background-color: #009344;
                    }
                }
                @include media("<=tablet"){
                    font-size: 14px;
                }
            }
        }
        
    }
    .footer-bottom-container { 
    
    }
    
    
}