

.banner-3-health-container {
    min-height: 100vh;
    width: 100%;
    @include media("<=tablet") {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

    }
    .banner-3-health-section {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 0px 120px 0px 120px;
        @include media("<=tablet") {
            margin: 0px 20px 0px 20px;   
        }
        .banner-3-health-title {
            height: 10%;
            width: 100%;
            color:   #1A1F66;
            font-size: 40px;
            font-weight: 700;
        }
        .banner-3-health-content {
            height: 400px;
            width: 100%;
            @include media("<=tablet") {
                height: auto; 
            }
            .banner-3-health-backpattern-container {
                
                position: absolute;
                z-index:0;
                display: flex;
                @include media("<=tablet") {
                    display: none;
                }
                .banner-3-health-backpattern {
                    height: 400px;
                    position: relative;
                    display: flex;
                    width: 1200px;
                    margin-top: 120px;
                    margin-left: 280px;
                    @include media("<=desktop") {
                        margin-left: 60px;
                    }
                }
            }
            .banner-3-health-grid-items-container {
                background-color: transparent;
                height: 400px;

                width: 80%;
                position: absolute;
                display: grid;
                margin: 0px 100px 0px 100px;
                grid-template-columns: repeat(4, 1fr);
                justify-content: space-around;
                align-items: flex-end;
                @include media("<=desktop") {
                    width: 80%;
                    display: grid;
                    margin: 0px 40px 0px 40px;
                    
                }
                @include media("<=tablet") {
                    grid-template-columns: 1fr;
                    height: auto !important;   
                    margin: 0px;
                    position: relative;
                }
                .banner-3-health-grid-item {
                    height: 300px;
                    width: 200px;
                    
                    background-color: #8CC63F;
                    border-radius: 8px;
                    box-shadow: 0px 2px 5px 5px rgba(0,0,0,0.1);
                    position: relative;
                    @include media("<=tablet") {
                        width: 100%;
                        margin: 30px 0px 30px 0px;
                    }
                    .grid-3-health-icon-container {
                        height: 30%;
                       
                       
                        display: flex;
                        justify-content: center;
                        align-items: center;
                            
                

                    }
                    .grid-3-health-text {
                        color: #444;
                        padding: 0px 20px 0px 20px;
                        
                    }
                }
            }
        } 
    }
    .banner-3-health-section-vendor {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 40px 120px 0px 120px;
        @include media("<=tablet") {
            margin: 0px 20px 0px 20px;
        }
        .banner-3-health-title {
            height: 10%;
            width: 100%;
            color:   #1A1F66;
            font-size: 40px;
            font-weight: 700;
        }
        .banner-3-health-content {
            height: 400px;
            width: 100%;
            @include media("<=tablet") {
              
                height: auto !important;    
            }
            .banner-3-health-backpattern-container {
                
                position: absolute;
                z-index:0;
                display: flex;
                @include media("<=tablet") {
                    display: none;
                }
                .banner-3-health-backpattern {
                    height: 400px;
                    position: relative;
                    display: flex;
                    width: 1200px;
                    margin-top: 120px;
                    margin-left: 280px;
                    @include media("<=desktop") {
                        margin-left: 60px;
                    }
                }
            }
            .banner-3-health-grid-items-container {
                background-color: transparent;
                height: 400px;
                width: 80%;
                position: absolute;
                display: grid;
                margin: 0px 100px 0px 100px;
                grid-template-columns: repeat(4, 1fr);
                justify-content: space-around;
                align-items: flex-end;
                @include media("<=desktop") {
                    width: 80%;
                    display: grid;
                    margin: 0px 40px 0px 40px;
                    position: relative;
                }
                @include media("<=tablet") {
                    grid-template-columns: 1fr;
                    height: auto !important;   
                    margin: 0px;
                }
                .banner-3-health-grid-item-vendor {
                    height: 300px;
                    width: 200px;
                    background-color: #1A1F66;
                    border-radius: 8px;
                    box-shadow: 0px 2px 5px 5px rgba(0,0,0,0.1);
                    position: relative;
                    @include media("<=tablet") {
                        margin: 30px 0px 30px 0px;
                        width: 100%;    
                    }
                    .grid-3-health-icon-container {
                        height: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                    }
                    .grid-3-health-text {
                        color: white;
                        padding: 0px 20px 0px 20px;
                        
                    }
                }
            }
        } 
    }
}