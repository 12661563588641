.general-button-container {
    width: 140px;
    display:flex;
    border-radius: 36px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 36px;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    text-decoration: none;
}