

.banner-2-container {
    height: 85vh;
    width: 100vw;
    display: flex;
    position: relative;
   
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include media("<=tablet") {
        height:110vh
        
    }
    .banner-2-container-left {
        width: 50%;
        height:100%;
        .banner-2-container-left-text {
            height:100%;
            width: 100%;
           .banner-2-conainer-left-margin-top{
                font-size: 42px;
                height:20%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding-left: 100px;
                color: #1A1F66;
                font-weight: 700;
                @include media("<=desktop") {
                    padding-left: 30px;
                    
                }
                @include media("<=tablet") {
                    display:none;
                }
           } 
           .banner-2-container-left-content {
            .banner-2-conainer-left-title {
                font-size: 42px;
                height:60px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                margin-bottom: 20px;
                color: #1A1F66;
                font-weight: 700;
                @include media("<=desktop") {
                    padding-left: 30px;
                    
                }
                @include media("<=tablet") {
                    padding-left: 0px;
                    
                }
           } 
            height:80%;
            display: flex;
            padding-top: 30px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 100px;
            @include media("<=desktop") {
                padding-left: 30px;
                
            }
            @include media("<=tablet") {
               padding: 30px 0px 0px 0px;
                
            }
           }
        }
        @include media("<=tablet") {
            width: 100%;
            padding: 30px;
            
        }
    }
    .banner-2-container-right {
        width: 50%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media("<=tablet") {
            display: none;
            
        }
        .banner-2-container-right-img-container {
            
            .banner-2-container-right-img {
                object-fit: cover;
                
                
            }
        }
    }
}
