
.banner-1-container {
    overflow-x: hidden;
    position: relative;
    overflow-y: hidden;
    display: flex;
    width: 100vw;
    height: 65vh;
    .banner-1-img-container {
        z-index: 2;
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        .banner-1-img {
            
            object-fit: cover;
            max-height:70vh;
            min-height: 70vh;
            width: 100%;
            
        }
    }
    .banner-1-overlay-container {
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        background: linear-gradient(90deg, rgba(26,31,102,1) 0%, rgba(26,31,102,.96) 50%, rgba(0,0,0,0) 100%);
        .banner-1-overlay-container-right {
            position:relative;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include media("<=tablet") {
               display: none;
            }
        }
        .banner-1-overlay-container-left {
            position:relative;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include media("<=tablet") {
                width: 100%;

            }
            .banner-1-overlay-container-left-text-container {
            position:relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            
    
                .banner-1-overlay-container-left-text-container-title {
                    position:relative;
                    width: 100%;
                    color: white;
                    height: 50%;
                    font-size: 42px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    padding-left: 100px;
                    margin:0;
                    @include media("<=tablet") {
                        padding: 0px;
                        margin-left: 20px;
                        font-size:24px;
                        
                    }
                }
                .banner-1-overlay-container-left-text-container-subtitle {
                    position:relative;
                    width: 100%;
                    color: white;
                    height: 12.5%;
                    font-size: 24px;
                    margin:0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-top: 20px;
                    padding-left: 100px;
                    @include media("<=tablet") {
                        padding-left: 0px;
                        margin-left: 20px;
                        font-size: 12px;
                        
                    }
                }
                .banner-1-overlay-container-left-text-container-button-container {
                    position:relative;
                    width: 100%;
                    color: white;
                    height: 37.5%;
                    font-size: 24px;
                    margin:0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-top: 20px;
                    padding-left: 100px;
                    @include media("<=tablet") {
                        padding-left: 0px;
                        margin-left: 20px;
                        font-size: 12px;
                        
                    }
                    .banner-1-overlay-container-left-text-container-button{
                        color: white;
                        background-color: #8CC63F;
                        padding: 10px 18px 10px 18px;
                        border-radius: 6px;
                        text-decoration: none;
                        font-weight: 600;
                        transition: .4s all ease-in-out;
                        &:hover {
                            background-color: #6e9c30;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        
    }
    
}
