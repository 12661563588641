
.header-container {
    z-index: 4;
    min-height: 70px;
    outline: none;
    position: fixed;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    transition: all 0.4s ease-in-out;
    width: 100vw;
    grid-template-columns: repeat(3, 1fr);
    @include media("<=phone"){
        width: 100vw;
    }
    .container-right {
        display: flex;
       
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 20px;
        .header-anchor {
            color:  white;
            text-decoration: none;
            padding:10px;
            outline: none;
            transition: .4s color ease-in-out;
        
        .header-logo {
            object-fit: cover;
            height:40px;
            margin-top: 20px;
            margin-left: 60px;
            border-radius: 10px;
            transition: all 1s ease-in-out;
            &:hover {
                transform: scale(1.1)
            }
            @include media("<=tablet"){
                height: 30px;
                margin-left: 8px;
                
            }
        }
            // &:after {
            //     content: "";
            //     position: relative;
            //     width: 0;
            //     height: 2px;
            //     display: block;
            //     margin-top: 5px;
            //     right: 0;
            //     background: $primary;
            //     transition: 0.4s ease;
            // }
            // &:hover {
            //     color: $primary;
            //     &:after {
            //         position: relative;
            //         width: 100%;
            //         left: 0;
            //         background: $primary;
            //     }
            // }
        }
        @include media("<=phone") {
            
            
        }
    }

    .container-left {
       
        grid-column: 3 / 4;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @include media("<=phone") {
            margin-right: 8px;
        
        }

        .hamburger-menu-clickable {
            display: none;
        }

        @include media("<=tablet"){
            .hamburger-menu-clickable {
                transition: all 0.4s ease-in-out;
                display: flex;
                height: 42px;
                width: 42px;
                margin-right: 8px;
                align-items: center;
                    justify-content: center;
                &:hover {
                    cursor: pointer;

                }
                .hamburger-menu {
                    transition: all 0.4s ease-in-out;
                    height: 80%;
                    
                    width: 80%;
                    border-radius: 6px;
                    padding: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    justify-content: space-around;
                    .hamburger-stripe {
                        height: 3px;
                        width: 80%;
                        background-color: rgba(255,255,255,1);
                        margin: 2px;
                    }
                    .hamburger-stripe-dark {
                        height: 3px;
                        width: 80%;
                        background-color:#1A1F66;
                        margin: 2px;
                    }
                   
                }
                .hamburger-close-button {
                    transition: all 0.4s ease-in-out;
                    height: 32px;
                    width: 32px;
                    color: rgba(255,255,255,0.6);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgba(255,255,255,0.12);
                    border-radius: 100%;
                }
                .hamburger-close-button-dark {
                    transition: all 0.4s ease-in-out;
                    height: 32px;
                    width: 32px;
                    color: #1A1F66;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #1A1F66;
                    border-radius: 100%;
                }
                
            }    
        } 
    
        .container-left-anchors {
            display: flex;
            flex-direction: row;
            @include media("<=tablet"){
                display: none;
            }
            .header-anchor {
                padding: 10px;
                color: white;
                text-decoration: none;
                font-size: 18px;
                outline: none;
                transition: .4s color ease-in-out;
                &:after {
                    content: "";
                    position: relative;
                    width: 0;
                    height: 2px;
                    display: block;
                    margin-top: 5px;
                    right: 0;
                    background: $primary;
                    transition: 0.4s ease;
                }
                &:hover {
                    color: $primary;
                    &:after {
                        position: relative;
                        width: 100%;
                        left: 0;
                        background: $primary;
                    }
                }
            }
            .header-anchor-button {
                padding: 10px 16px 10px 16px;
                background-color: #8CC63F;
                color: white;
                text-decoration: none;
                font-size: 16px;
                width: 100px;
                text-align: center;
                outline: none;
                border-radius: 4px;
                margin: 0px 8px 0px 8px;
                transition: .4s all ease-in-out;
                
                &:hover {
                    background-color: #6e9c30;
                    cursor: pointer;
                }
            }
        }
        
    }
    
    .header-anchor-show {
        height: auto;
        transition: all 0.4s ease-in-out;
        height: 30px;
        font-size: 24px;
    }
    .header-bottom-container {
        display: none;
    }

    @include media("<=tablet") {
        
        .header-bottom-container {
            height: 0.1;
            display: grid;
            transition: all 0.2s ease-in-out;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            background: transparent;
            grid-column: 1 / 4;
            .container-left-anchors {
                transition: all 0.4s ease-in-out;
                display: flex;
                flex-direction: column;
                .header-anchor {
                    transition: all 0.4s ease-in-out;
                    height: 0px;
                    font-size: 0px;
                    text-decoration: none;
                    color: $white;
                    
                    margin: 2px 0px 6px 16px;
                    &:hover {
                        cursor: pointer;
                    }                
                }
                .header-anchor-show {
                    height: auto;
                    transition: all 0.4s ease-in-out;
                    height: 30px;
                    font-size: 24px;
                    &:hover {
                        cursor: pointer;
                    } 
                }
                .header-anchor-white {
                    color: white;
                }
                .header-anchor-black {
                    color: #1A1F66;
                }

            }
            
        }
        .header-bottom-container-opened {
            height: auto;
            background: white;
           
           
        }
        .header-bottom-container-transparent{
           
            background: transparent;
           
        }
        .header-bottom-container-white{
    
            background: white;
           
        }
    }
    
}

.header-container-transparent {
    background: transparent;
}
.header-container-white {
    background: white;
}