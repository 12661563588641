
.banner-4-health-container {
    min-height: 50vh;
    min-width: 100vw;
    margin-left: 120px;
    display: flex;
    flex-direction: column;
    @include media("<=tablet") {
        margin-left: 20px;
        height: 30vh;
    }
    .title-banner-4-health-container {
        height: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        

        .title-banner-4-health {
            
            font-size: 42px;
            font-weight: 600;
            color: #1A1F66;
            @include media("<=tablet") {
               
                font-size: 36px;
            }
        }
    }
    .partners-logos-container {
        height: 80%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @include media("<=tablet") {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            
        }
        .partner-logo-container {
            
            
            object-fit: cover;
            height: 100%;
            width: 100%;
            @include media("<=tablet") {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -20px;
            }
        }
    }
}
