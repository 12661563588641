.banner-1-sectors-container {
    width: 100vw;
    min-height: 30vh;
    background-color: #f8f8f8;
    box-shadow: 0px 1px 2px 3px rgba( 0,0,0,0.2);
    position: relative;
    
    .sector-title {
        height: 10vh;
        display: flex;
        font-size: 30px;
        font-weight: 700;
     
       
        width: 500px;
        border:1px solid rgba( 0,0,0,0.1);
        border-radius: 0px 0px 20px 0px;
        color: #333a9b;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media("<=tablet") {
            font-size: 16px;
            justify-content: flex-start ;
            padding-left: 20px;
        }
    }
    
    .sector-items-row {
        background-color: #f8f8f8;
        height: 20vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        
        position: relative;
        .sector-item-container {
            background-color: #f8f8f8;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
            height: 100%;
            flex-direction: column;
            .sector-item-icon-circle {
                height: 90px;
                margin-top: 20px;
                width: 90px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background: saddlebrown;
                border-radius: 100%;
                background-color: white;
                box-shadow: 1px 1px 3px 0.5px rgba( 0,0,0,0.2);
                border-color: rgba( 0,0,0,0.2);
                &:hover {
                    border-color: rgba( 0,0,0,0.4);
                    box-shadow: inset -1px -1px 3px 0.5px rgba( 0,0,0,0.2);
                }
                @include media("<=tablet") {
                    height: 50px;
                    width: 50px;
                    
                    
                }
                .sector-item-icon {
                    &:hover {
                       height: 10px;
                       font-size: 10px;
                    }
                }
            }
            .sector-item-title {
                font-size: 22px;
                font-weight: 600;
                color: rgba( 0,0,0,0.5);
                @include media("<tablet") {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba( 0,0,0,0.5);
                }
            }
        }
    }
}