

.banner-3-container {
    min-height: 100vh;
    width: 100vw;
    background-color: #009344;
    @include media("<=desktop") {
        
       
        
    }
    .banner-3-section-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        .banner-3-section-title {
            height: 60px;
            width: 100%;
            padding: 30px;
            font-size: 42px;
            font-weight: 700;
            color: white;
            margin-bottom: 30px;
            @include media("<=desktop") {
                height: 40px;
                padding: 10px 10px 10px 30px;
                
                
            }
        }
        .banner-3-section-items-container {
           
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            @include media("<=desktop") {
               
                justify-content: flex-start;
            }
            @include media("<=tablet") {
               
                justify-content: center;
                width: 100%;
            }
            .banner-3-section-item {
               
                width: 25%;
               
                display: flex;
                flex-direction: column;
             
                @include media("<=desktop") {
                    
                    width: 25%;
                    
                }
                @include media("<=tablet") {
                    
                    width: 100%;
                    
                    
                }
                .banner-section-item-container {
                    padding-right: 50px;
                    padding-left: 50px;
                    .banner-3-section-item-header {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin-left: 20px;
                        height: 50px;
                        
                        .banner-3-section-item-header-icon {
                            height: 36px;
                        }
                        .banner-3-section-item-header-title {
                            color: white;
                            font-weight: 600;
                            padding-left: 10px;
                        }
                    }
                    .banner-3-section-item-content {
                        height: 160px;
                        margin: 20px;
                        color: white;
                    }
                }
                
            }
        }
    }
}